import { QuestionAndAnswer } from '../../components/common/q-and-a'

const questionsAndAnswers: QuestionAndAnswer[] = [
    {
        question: 'How does the 30-day free trial work?',
        answer: 'To access the Services, you have to create an account and provide valid billing information. When the Trial Period ends, we will automatically charge you, except if you already cancelled your subscription.'
    },
    {
        question: 'Do I get a 30-day free trial if I upgrade?',
        answer: 'You are eligible for a Trial Period if you currently do not subscribe to our Service Offerings.'            
    },
    {
        question: 'Must I actively renew my plan before it expires?',
        answer: 'Your current order Term will automatically renew for an equal order Term, except if you or we end the Services.'            
    },
    {
        question: 'Can I change plans at any time?',
        answer: 'Yes'            
    },
    {
        question: 'Do I have to sign a long term contract?',
        answer: 'No. Signatu is a SaaS monthly subscription service. You simply pay monthly or yearly, and you can cancel at any time. '            
    },
    {
        question: 'What types of payment do you accept?',
        answer: 'We accept online payment with credit cards. Enterprise Customers can also pay by ordinary bank transfer.'            
    },
    {
        question: 'Who pays?',
        answer: 'You pay for your account. If an account is transferred to a new owner, then the new owner pays.'            
    },
    {
        question: 'What is a consent event that counts as an event API call?',
        answer: 'We register via API calls to our server an end user’s consent events. An end user’s consent events are an end user’s active consent, refusal to consent (if refuse buttons are used) and withdrawal of consent.'
    },
    {
        question: 'Can I cache an end user’s consent in user’s browser?',
        answer: 'That is possible, but seek the advice of your legal counsel to understand the legal risk.'
    },
]

export default questionsAndAnswers
